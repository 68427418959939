// src/App.tsx
import React, { useEffect } from 'react';
import { CartProvider } from './contexts/CartContext';
import Home from './pages/Home';
import Header from './components/Header';
import {FilterProvider} from "./contexts/FilterContext";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CartPage from "./pages/CartPage";
import {ProductPopupProvider} from "./contexts/ProductPopupContext";
import ProductDetailsPopup from "./components/ProductDetailsPopup";
import ProductDetail from "./components/ProductDetail";
import CheckoutPage from "./pages/CheckoutPage";
import Orders from "./pages/Orders";
import {AuthMiddlewareProvider} from "./contexts/AuthMiddlewareContext";
import ErrorPage from "./pages/ErrorPage";
import Services from './pages/Services';
import { ServicePopupProvider } from './contexts/ServicePopupContext';
import ServiceDetailsPopup from './components/ServiceDetailsPopup';
import Confirmation from './pages/Confirmation';
import ReactGA from 'react-ga4';

// Google Analytics başlatma
ReactGA.initialize('G-2W184V72FN');

// Route değişikliklerini izleyen bileşen
const RouteTracker: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null;
};

const App: React.FC = () => {
    return (
        <FilterProvider>
            <Router>
            <AuthMiddlewareProvider>
            <CartProvider>
                <ProductPopupProvider>
                    <ServicePopupProvider>
                        <RouteTracker />
                        <div className="App">
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/services" element={<Services />} />
                                    <Route path="/confirmation/:orderId" element={<Confirmation />} />
                                    <Route path="/error" element={<ErrorPage />} />
                                    <Route path="/orders" element={<Orders />} />
                                    <Route path="/cart" element={<CartPage />} />
                                    <Route path="/checkout" element={<CheckoutPage />} />
                                </Routes>
                        </div>
                        <Header />
                    <ProductDetailsPopup />
                    <ProductDetail />
                    <ServiceDetailsPopup />
                </ServicePopupProvider>
                </ProductPopupProvider>
            </CartProvider>
            </AuthMiddlewareProvider>
            </Router>
        </FilterProvider>
    );
};

export default App;