// src/components/ProductDetailsPopup.tsx
import React, { useEffect, useState } from 'react';
// @ts-ignore
import Modal from 'react-modal';
import axiosInstance from '../axiosInstance';
import { useProductPopup } from '../contexts/ProductPopupContext';
import ClipLoader from 'react-spinners/ClipLoader';
import {ProductType, SubProductType} from "../types/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useCart} from "../contexts/CartContext";
import {useAuthMiddleware} from "../contexts/AuthMiddlewareContext";


const customStyles: Modal.Styles = {
    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1000 },
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        padding: '10px',
        width: '90%',
        maxHeight: '80vh',
        overflowY: 'auto',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    },
};

const formatCurrency = (amount: number) =>
    new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 2 })
        .format(amount)
        .replace('₺', '') // TRY sembolünü kaldırmak isterseniz

const ProductDetailsPopup: React.FC = () => {
    const { userData } = useAuthMiddleware();
    const { productId, closePopup } = useProductPopup();
    const [product, setProduct] = useState<ProductType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [maxQuantity, setMaxQuantity] = useState<number>(0);

    const { addToCart, getItemQuantity, removeFromCart } = useCart(); // Cart fonksiyonları

    const fallbackImage = 'https://panel.lastsis.com/media/default-images/tire-default.jpeg';

    useEffect(() => {
        if (productId !== null) {
            setLoading(true);
            axiosInstance
                .get(`/ecommerce/products/${productId}?city=${userData?.il}`)
                .then((response) => setProduct(response.data.data))
                .catch(() => setError('Satıcı bilgileri alınırken hata oluştu.'))
                .finally(() => setLoading(false));
        }
    }, [productId]);

    if (!productId) return null; // Popup kapalıysa hiçbir şey render etmiyoruz
    const handleAddToCart = (seller: SubProductType) => {
        let quantity = getItemQuantity({
            product_id: seller.id
        });
        console.log(quantity, seller.quantity)
        if (quantity < seller.quantity) {
            addToCart(seller?.firm.id, {
                product_id:seller.id
            });
        } else {
            alert('Stokta yeterli ürün yok.');
        }
    };

    const AddToCartButton: React.FC<{ seller: SubProductType }> = ({ seller }) => {
        const quantity = getItemQuantity({
            product_id: seller.id
        });
        setMaxQuantity(seller.quantity);

        return (
            <div className="flex items-center space-x-2">
                {quantity > 0 ? (
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => removeFromCart({
                                product_id: seller.id
                            })}
                            className="px-2 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition text-[10px]"
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className="text-md">{quantity}</span>
                        <button
                            onClick={() => handleAddToCart(seller)}
                            className="px-2 py-1 bg-green-500 text-white rounded-full hover:bg-green-600 transition text-[10px]"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => handleAddToCart(seller)}
                        className="px-2 py-1 bg-blue-500 text-white rounded-[6px] hover:bg-blue-600 transition text-[12px]"
                    >
                        <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                        Ekle
                    </button>
                )}
            </div>
        );
    }

    return (
        <Modal isOpen={!!productId} onRequestClose={closePopup} style={customStyles} ariaHideApp={false}>
            {loading ? (
                <div className="flex justify-center items-center">
                    <ClipLoader color="#3498db" size={50} />
                </div>
            ) : error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : (
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-4">
                        <img
                            className="w-[50px]"
                            alt={product?.name}
                            src={`https://d2buqeq24qhiv5.cloudfront.net/250x250/pool/${product?.real_image}`}
                            onError={(e) => {
                                (e.target as HTMLImageElement).src = fallbackImage;
                            }}
                        />
                        <div>
                            <h2 className="text-xl font-bold">{product?.name}</h2>
                            <p className="text-sm text-gray-600">{product?.category}</p>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {product?.all_products && product?.all_products.map((seller) => (
                            <div key={seller.id} className="flex items-center justify-between border-b pb-2">
                                <div className="flex items-center space-x-4">
                                    <img
                                        src={seller?.firm?.logo ? `https://lastsis.s3.eu-central-1.amazonaws.com/lastsis/uploads/firms/${seller?.firm?.logo}` : 'https://panel.lastsis.com/favicon.ico'}
                                        alt={seller.title}
                                        className="w-12 h-12 object-contain rounded-full"
                                    />
                                    <div>
                                        <h3 className="text-md font-bold text-[13px]">{seller?.firm?.title}</h3>
                                        <p className="text-sm text-gray-500 text-[10px]">
                                            {seller?.firm?.city}, {seller?.firm?.county}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center space-x-2">
                                    <div className="flex flex-row">
                                        <div className={`p-1 rounded-[6px] text-[10px] bg-red-500 bg-opacity-10 text-red-500 font-semibold mr-2 mb-1`}>
                                            {seller.dot ?? '-'}
                                        </div>
                                        <p className="text-md font-semibold">{seller.quantity > 20 ? '20+' : seller.quantity} adet</p>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className={`p-1 rounded-[6px] text-[10px] bg-blue-500 bg-opacity-10 text-blue-500 font-semibold mr-2 whitespace-nowrap`}>
                                            {formatCurrency(parseFloat(seller.price) + parseFloat(seller.total_vat))} ₺
                                        </div>
                                        <AddToCartButton seller={seller} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={closePopup}
                        className="mt-4 px-4 py-2 bg-black bg-opacity-10 text-black rounded-[6px] transition"
                    >
                        Kapat
                    </button>
                </div>
            )}
        </Modal>
    );
};

export default ProductDetailsPopup;