// src/components/Product.tsx
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudMoonRain, faSnowflake, faSun } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faGasPump, faCloudShowersHeavy, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { ProductType, SubProductType } from '../types/types';
import AddToCartButton from './AddToCartButton';
import { useCart } from '../contexts/CartContext';
import {useProductPopup} from "../contexts/ProductPopupContext";

interface ProductProps {
    product: ProductType;
}

const Product: React.FC<ProductProps> = ({ product }) => {
    const { cartItems } = useCart();
    const [selectedProduct, setSelectedProduct] = useState<SubProductType>(product.products[0]);
    const { openPopup, openProductDetails } = useProductPopup();

    // Yedek resim kaynağı
    const fallbackImage = 'https://panel.lastsis.com/media/default-images/tire-default.jpeg';

    useEffect(() => {
        const cartProduct = cartItems.find((item) =>
            product.products.some((p) => p.id === item.product_id)
        );

        if (cartProduct) {
            const matchedProduct = product.products.find((p) => p.id === cartProduct.product_id);
            if (matchedProduct) {
                setSelectedProduct(matchedProduct);
            }
        } else {
            setSelectedProduct(product.products[0]);
        }
    }, [cartItems, product.products]);

    return (
        <div className="w-1/2">
            <div className="p-1">
                <div className="w-full bg-white flex justify-between items-center p-2">
                    <span className="season-icon leading-[10px]">
                        {product?.season ? (
                            <>
                                {product?.season.includes('Yaz') ? (
                                    <FontAwesomeIcon icon={faSun} className="text-yellow-500 h-7" title="Yaz Lastiği" />
                                ) : product?.season.includes('Kış') ? (
                                    <FontAwesomeIcon icon={faSnowflake} className="text-blue-500 h-7" title="Kış Lastiği" />
                                ) : (
                                    <FontAwesomeIcon icon={faCloudMoonRain} className="text-dark-500 h-7" title="Dört Mevsim" />
                                )}
                            </>
                        ) : null}
                    </span>
                    <div className="flex flex-row">
                        <div className="flex justify-center text-center space-x-2">
                            <div className="flex flex-col items-center">
                                <div>
                                    <FontAwesomeIcon icon={faGasPump} className="text-yellow-500 h-3" />
                                </div>
                                <div className="leading-[10px]">
                                    <span className="mr-1 text-[10px]">{product.energy_class}</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div>
                                    <FontAwesomeIcon icon={faCloudShowersHeavy} className="text-blue-500 h-3" />
                                </div>
                                <div className="leading-[10px]">
                                    <span className="mr-1 text-[10px]">{product.brake_performance}</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div>
                                    <FontAwesomeIcon icon={faVolumeUp} className="text-red-500 h-3" title="Değer db cinsindendir." />
                                </div>
                                <div className="leading-[10px] whitespace-nowrap">
                                    <span className="text-[10px]">{product.noise_level}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white">
                    <img
                        className="w-100"
                        alt={product.name}
                        style={{ width: '100%', transform: 'scaleX(-1)' }}
                        src={`https://d2buqeq24qhiv5.cloudfront.net/250x250/pool/${product.real_image}`}
                        onError={(e) => {
                            (e.target as HTMLImageElement).src = fallbackImage;
                        }}
                    />
                </div>
                <div className="col-12 cat-pro-info bg-white p-2">
                    <a href="#" className="flex w-100" title={product.name}>
                        <h3
                            className="font-bold text-[13px] text-[#333] leading-[16px] overflow-hidden text-ellipsis"
                            style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                            title={product.name}
                        >
                            {product.name}
                        </h3>
                    </a>
                    <div className="cat-pro-brand-info my-1 flex flex-col w-100">
                        <strong className="prod-brand-title">
                            <a href="#" className="text-[#1959d1] text-[14px] mr-2">{product.brand}</a>
                            <span className="text-[12px] text-[#333]">({product.stock_code})</span>
                        </strong>
                        <div className="flex flex-row justify-between items-center center my-3">
                            <strong className="text-[16px] text-[#0a8c06]">{selectedProduct?.vatted_price_formatted ?? 0} TL</strong>
                            <span className="text-[12px] text-[#333] font-bold cursor-pointer" onClick={() => openPopup(product.id)}>Satıcılar ({selectedProduct?.firm_count ?? 0})</span>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center center my-3">
                        <div className={`bg-green-500 bg-opacity-10 border-green-500`}></div>
                        {product.products.map((item) => (
                            <div className="p-1">
                                <div key={item.id}
                                     onClick={() => setSelectedProduct(item)}
                                     className={`flex flex-col rounded-5 bg-${selectedProduct?.id==item.id ? 'green' : 'blue'}-500 bg-opacity-10 items-center center border border-${selectedProduct?.id==item.id ? 'green' : 'black'}-500 p-1`}>
                                    <span className="text-[12px] text-[#333]">{item.dot}</span>
                                    <strong className="text-[10px] text-[#333]">{item.vatted_price_formatted ?? 0} TL</strong>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-between items-center space-x-4">
                        <button
                            onClick={() => openProductDetails(product.id)} // Butona tıklandığında popup açılır
                            className="flex items-center px-2 py-1 text-[12px] border border-blue-500 text-blue-500 bg-transparent rounded-[6px] hover:bg-blue-500 hover:text-white transition"
                        >
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            <span>İncele</span>
                        </button>
                        <AddToCartButton productId={selectedProduct.id} maxQuantity={selectedProduct.quantity} poolId={product.id} firmId={selectedProduct.firm_id} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;